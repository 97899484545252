import { Link } from "gatsby"
import React from "react"
import logoWhite from "../assets/img/logo-corsaf-blanc.png"
import facebook from "../assets/icons/social/facebook.png"
import twitter from "../assets/icons/social/twitter.png"
import pinterest from "../assets/icons/social/pinterest.png"
import youtube from "../assets/icons/social/youtube.png"
import instagram from "../assets/icons/social/instagram.png"
import linkedin from "../assets/icons/social/linkedin.png"


function Footer({}) {
  return(
      <footer class="w-full bg-black">
        <div class="grid grid-cols-1 md:grid-cols-4 text-white mt-8 gap-8 md:mx-40 md:my-5 mx-3">
            <div> 
                <img src={logoWhite} alt="Logo Corsaf" class="py-6" />
                <p class="text-xs gray">La société <span class="font-bold">CORSAF DESIGN </span>est l’un des leaders Marocains spécialisé dans la Transformation du Solid Surface (marque leader de résine acrylique).</p>
            </div>
            <div class="specialite">
                <h3 class="text-white pb-2 divider mb-3 py-6"> NOS SPÉCIALITÉS</h3>
                <Link to={`/solid-surface`}><p class="mb-0 text-xs hover:text-gray-500"> <span class="font-bold text-base">&rsaquo;</span> Solid Surface</p></Link>
                <Link to={`/solid-surface`}><p class="mb-0 text-xs hover:text-gray-500"> <span class="font-bold text-sm">&rsaquo;</span> Quartz</p></Link>
            </div>
            <div>
                <h3 class="text-white pb-2 divider mb-3 py-6"> INFORMATIONS</h3>
                <ul class="text-sm">
                    <li class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="22" height="22" class="inline mr-2">
                        <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                        </svg>
                        <p class="gray">+212 (0)528 247 711</p>
                    </li>
                    <li class="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" class="inline mr-2" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                    </svg> <p class="gray">ste.corsaf@gmail.com</p>
                    </li>
                    <li class="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" class="inline mr-2" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                        </svg>
                        <p class="gray text-xs"> Imm GDIRA 3ème étage N°8 BV Mohamed 6, Ait Melloul - Agadir</p>
                    </li>
                </ul>
                <div> 
                    <ul class="w-5/6 mx-auto flex items-center gap-1">
                        <li><a href="https://www.facebook.com/SteCorsaf" target="top"><img src={facebook} class="rounded-full p-1 bg-white" alt="facebook icon" /></a></li>
                        <li><a href="https://twitter.com/Ste_Corsaf" target="top"><img src={twitter} class="rounded-full p-1 bg-white" alt="twitter icon" /></a></li>
                        <li><a href="https://fr.pinterest.com/stecorsaf" target="top"><img src={pinterest} class="rounded-full p-1 bg-white" alt="pinterest icon" /></a></li>
                        <li><a href="https://www.youtube.com/channel/UCpUgD68WJ0E2bK9OirZnilA" target="top"><img src={youtube} class="rounded-full p-1 bg-white" alt="youtube icon" /></a></li>
                        <li><a href="https://www.instagram.com/ste_corsaf" target="top"><img src={instagram} class="rounded-full p-1 bg-white" alt="instagarm icon" /></a></li>
                        <li><a href="https://www.linkedin.com/company/ste-corsaf/" target="top"><img src={linkedin} class="rounded-full p-1 bg-white" alt="linkedin icon" /></a></li>
                    </ul>
                </div>
            </div>
            <div>
                <h3 class="text-white pb-2 divider mb-3 py-6"> NOTRE LOCALISATION</h3>
              <div class="mt-3"> <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13772.531423582876!2d-9.511576204846195!3d30.347173923148606!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb27dfa4495318b58!2sCorsaf%20Design!5e0!3m2!1sen!2sma!4v1612386710632!5m2!1sen!2sma" width="100%" height="auto" frameborder="0"   allowfullscreen="" aria-hidden="false" tabindex="0"></iframe></div> 
            </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-4 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
            <div class="col-span-1 md:col-span-3 text-gray-200 text-xs">
            © Copyright Ste Corsaf sarl 2021
            </div>
            <div class="text-gray-200 text-xs text-right">Conçu par<a href="https://www.diwandev.com/" target="top"> diwandev</a></div>
        </div>
      </footer>
  )
}

export default Footer