import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import logo from "../assets/img/logo.png"
import logoMobile from "../assets/img/logo-mobile.png"

function Header({ siteTitle }) {
  const [isExpanded, toggleExpansion] = useState(false)
  return(
    <header>
     {/* <div class="grid grid-cols-1 mt-8 gap-8 md:mx-40 md:my-5 mx-3 md:block hidden"> {/* social icons  
        <div class="float-right inline-block flex">
        <svg role="img" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><title>Facebook icon</title><path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/></svg>
        <svg role="img" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><title>Twitter icon</title><path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"/></svg>
        <svg role="img" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24"><title>YouTube icon</title><path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"/></svg>
        </div>
      </div>*/}
      <div class="md:grid md:grid-cols-1 md:grid-cols-4 mt-8 gap-8 md:mx-40 md:my-5 mx-3 hidden">{/* contacts */}
        <div><Link to={`/`}><img src={logo} alt="Logo Corsaf"/></Link></div>
        <div class="flex items-center gap-1">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="25" height="25" class="inline mr-2">
          <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
          </svg>
            <p class="mb-0">+212 (0)528 247 711</p>
        </div>
        <div class="flex items-center gap-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" class="inline mr-2" viewBox="0 0 20 20" fill="currentColor">
          <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
          <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
          </svg> 
            <p class="mb-0">ste.corsaf@gmail.com</p>
        </div>
        <div class="flex items-center gap-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" class="inline mr-2" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
          </svg>
            <p class="mb-0">Avenue Mohamed VI, Agadir 80000</p>
        </div>

      </div>
      <nav className="md:px-5 uppercase text-white  justify-between bg-teal-500 px-3 py-1 md:px-6 md:py-4">
        <div class="flex justify-between">
        <div className="block lg:hidden">
          <button onClick={() => toggleExpansion(!isExpanded)} className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white">
            <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
          </button>
        </div>
        <div class="block lg:hidden"><Link to={`/`}><img src={logoMobile} alt="Logo Corsaf" class="m-0 bg-white rounded" /></Link></div>
        </div>
        <div className={`${ isExpanded ? `block` : `hidden` } w-full block flex-grow lg:flex lg:items-center lg:w-auto`}>
          <div className="text-sm mx-auto md:flex md:gap-5">
            <Link to={`/`} href="#responsive-header" className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
              Accueil
            </Link>
            <Link to={`/nous-sommes`} className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
              Qui Sommes-nous
            </Link>
            <Link to={`/solid-surface`} className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
              Solid Surface
            </Link>
            <Link to={`/quartz`} className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
              Quartz
            </Link>
         <div class="group inline-block mt-4 md:mt-0 mr-4">
          <div class="flex items-center">
            <span class="font-semibold flex-1 sub-menu">APPLICATIONS</span>
            <span>
              <svg
                class="fill-current h-4 w-4 transform group-hover:-rotate-180
                transition duration-150 ease-in-out"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </span>
          </div>
          <ul class="bg-gray-900 border z-10 rounded-sm transform scale-0 group-hover:scale-100 absolute transition duration-150 ease-in-out origin-top min-w-32">
            <li class="rounded-sm px-3 py-1 hover:bg-gray-300 hover:text-gray-900"><Link to={`/applications/agencement`}>Agencement</Link></li>
            <li class="rounded-sm px-3 py-1 hover:bg-gray-300 hover:text-gray-900"><Link to={`/applications/facade`}>Façade</Link></li>
            <li class="rounded-sm px-3 py-1 hover:bg-gray-300 hover:text-gray-900"><Link to={`/applications/hotellerie`}>Hôtellerie</Link></li>
            <li class="rounded-sm px-3 py-1 hover:bg-gray-300 hover:text-gray-900"><Link to={`/applications/cuisine`}>Cuisine</Link></li>
            <li class="rounded-sm px-3 py-1 hover:bg-gray-300 hover:text-gray-900"><Link to={`/applications/salle-de-bain`}>Salle De Bain</Link></li>
            <li class="rounded-sm px-3 py-1 hover:bg-gray-300 hover:text-gray-900"><Link to={`/applications/restauration`}>Restauration</Link></li>
            <li class="rounded-sm px-3 py-1 hover:bg-gray-300 hover:text-gray-900"><Link to={`/applications/sante-collectivite`}>Santé & Collectivité</Link></li>
            <li class="rounded-sm px-3 py-1 hover:bg-gray-300 hover:text-gray-900"><Link to={`/applications/vasque`}>Vasque</Link></li>
            <li class="rounded-sm px-3 py-1 hover:bg-gray-300 hover:text-gray-900"><Link to={`/applications/objet-design`}>Objet Désign</Link></li>
            <li class="rounded-sm px-3 py-1 hover:bg-gray-300 hover:text-gray-900"><Link to={`/applications/gravure`}>Gravure Dans Le Solid Surface</Link></li>
           </ul>
         </div>
            <Link to={`/realisations`} className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
              Nos Réalisations
            </Link>
         <div class="group inline-block mt-4 md:mt-0">
          <div class="flex items-center">
            <span class="font-semibold flex-1 sub-menu">Téléchargement</span>
            <span>
              <svg
                class="fill-current h-4 w-4 transform group-hover:-rotate-180
                transition duration-150 ease-in-out"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </span>
          </div>
          <ul class="bg-gray-900 z-10 border rounded-sm transform scale-0 group-hover:scale-100 absolute transition duration-150 ease-in-out origin-top min-w-32">
            <li class="rounded-sm px-3 py-1 hover:bg-gray-300 hover:text-gray-900"><Link to={`/telechargement/references`}>Nos Références</Link></li>
            <li class="rounded-sm px-3 py-1 hover:bg-gray-300 hover:text-gray-900"><Link to={`/telechargement/videotheque`}>Vidéothèque</Link></li>
            <li class="rounded-sm px-3 py-1 hover:bg-gray-300 hover:text-gray-900"><Link to={`/telechargement/catalogue`}>Catalogue</Link></li>
            <li class="rounded-sm px-3 py-1 hover:bg-gray-300 hover:text-gray-900"><Link to={`/telechargement/certificats`}>Certificats</Link></li>
            <li class="rounded-sm px-3 py-1 hover:bg-gray-300 hover:text-gray-900"><Link to={`/telechargement/guide`}>Guide</Link></li>
            <li class="rounded-sm px-3 py-1 hover:bg-gray-300 hover:text-gray-900"><Link to={`/telechargement/flayers`}>Flayers</Link></li>
            <li class="rounded-sm relative hover:bg-gray-300 hover:text-gray-900 px-3 py-1">
              <button
                class="w-full text-left flex items-center outline-none focus:outline-none"
              >
                <span class="pr-1 flex-1">BAS SURFACES</span>
                <span class="mr-auto">
                  <svg class="fill-current h-4 w-4 transition duration-150 ease-in-out"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </span>
              </button>
              <ul class="bg-gray-900 text-white border rounded-sm absolute top-0 right-0 transition duration-150 ease-in-out origin-top-left min-w-32">
                <li class="px-3 py-1 hover:bg-gray-300 hover:text-gray-900">
                <Link to={`/telechargement/bas-surface-coloris`}>Coloris</Link></li>
                <li class="px-3 py-1 hover:bg-gray-300 hover:text-gray-900">
                  <Link to={`/telechargement/bas-surface-fiche-technique`}>Fiche Technique</Link></li>
                <li class="px-3 py-1 hover:bg-gray-300 hover:text-gray-900">
                  <Link to={`/telechargement/bas-surface-certificats`}>Certificats</Link></li>
                <li class="px-3 py-1 hover:bg-gray-300 hover:text-gray-900">
                  <a href="http://www.birlesikakrilik.com/_en/index.html" target="top">Site Web</a></li>
               </ul>
             </li>
             <li class="rounded-sm relative hover:bg-gray-300 hover:text-gray-900 px-3 py-1">
              <button
                class="w-full text-left flex items-center outline-none focus:outline-none"
              >
                <span class="pr-1 flex-1">REHAU</span>
                <span class="mr-auto">
                  <svg class="fill-current h-4 w-4 transition duration-150 ease-in-out"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </span>
              </button>
              <ul class="bg-gray-900 text-white border rounded-sm absolute top-0 right-0 transition duration-150 ease-in-out origin-top-left min-w-32">
                <li class="px-3 py-1 hover:bg-gray-300 hover:text-gray-900">
                <Link to={`/telechargement/rehau-coloris`}>Coloris</Link></li>
                <li class="px-3 py-1 hover:bg-gray-300 hover:text-gray-900">
                  <Link to={`/telechargement/rehau-fiche-technique`}>Fiche Technique</Link></li>
                <li class="px-3 py-1 hover:bg-gray-300 hover:text-gray-900">
                  <Link to={`/telechargement/rehau-certificats`}>Certificats</Link></li>
                <li class="px-3 py-1 hover:bg-gray-300 hover:text-gray-900">
                <a href="https://www.rehau.com/group-en" target="top">Site Web</a></li>
               </ul>
             </li>
             <li class="rounded-sm relative hover:bg-gray-300 hover:text-gray-900 px-3 py-1">
              <button
                class="w-full text-left flex items-center outline-none focus:outline-none"
              >
                <span class="pr-1 flex-1">STONE ITALIANA</span>
                <span class="mr-auto">
                  <svg class="fill-current h-4 w-4 transition duration-150 ease-in-out"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </span>
              </button>
              <ul class="bg-gray-900 text-white border rounded-sm absolute top-0 right-0 transition duration-150 ease-in-out origin-top-left min-w-32">
                <li class="px-3 py-1 hover:bg-gray-300 hover:text-gray-900">
                <Link to={`/`}>Coloris</Link></li>
                <li class="px-3 py-1 hover:bg-gray-300 hover:text-gray-900">
                  <Link to={`/`}>Fiche Technique</Link></li>
                <li class="px-3 py-1 hover:bg-gray-300 hover:text-gray-900">
                  <Link to={`/`}>Certificats</Link></li>
                <li class="px-3 py-1 hover:bg-gray-300 hover:text-gray-900">
                  <a href="https://www.stoneitaliana.com/en">Site Web</a></li>
               </ul>
             </li>
           </ul>
         </div>   
            <Link to={`/actualite`} className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
              Acualité
            </Link>
            <Link to={`/contact`} className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
              Contact
            </Link>
          </div>
        </div>
      </nav>
  </header>
     )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
